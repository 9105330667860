@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #178cd5;
}



input:focus, input.form-control:focus {

    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}


.priceTable {
    border-collapse: collapse;
    width: 100%;
}
.priceTh {
  font-weight: bold;
  font-size: 30px;
  padding: 8px;
  text-align: left;
  height: 36px;
  line-height: 36px;
}
.priceThTd {
    padding: 8px;
    text-align: left;
    height: 36px;
    line-height: 36px;
}
.rowClass {
  font-size: 18px;
  font-weight: bold;
  border-left: 1px solid #ccc;
  text-align: center;
  vertical-align: middle;
}

.rowClass img {
    display: block; /* Ensure image is treated as block-level element */
    margin: 0 auto; /* Center the image horizontally */
}

.strikethrough {
    position: relative;
    display: inline-block;
    text-decoration: none; /* Supprime la ligne de texte barré par défaut */
}

.strikethrough::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 110%;
    height: 2px; /* Hauteur de la ligne barrée */
    background-color: #cc0078; /* Couleur de la ligne barrée */
    transform-origin: center;
    transform: translateY(-50%) rotate(-10deg); /* Rotation de 45 degrés */
}

.table {
  background: #fff;
}

.table-responsive-ext {
  background: #131313;
  color: red;
}

.footer_link_wrapper a {
  color: #111;
}

.footer_link_wrapper a:hover {
  color: #0079ff;
}

.btn-border-blue {
  border: 1px solid #2e659a;
}

.rce-citem-low-active {
  margin-top: 20px;
}



.rce-citem-low-col-default {
  background: #222733;
  color: #fff
}

.rce-citem-low-col-type-default {
  background: #222733;
  color: #fff
}

.rce-citem-low-col-categ-default {
  background: #9ea7b6;
  color: #fff
}

.rce-citem-low-col-low {
  background: #20af28;
  color: #fff
}

.rce-citem-low-col-mid {
  background: #e55501;
  color: #fff
}

.rce-citem-low-col-hi {
  background: red;
  color: #fff
}

.rce-citem-low-col-action-debyoo {
  background: red;
  color: #fff
}

.rce-citem-low-col-action-user {
  background: #1554d1;
  color: #fff
}

.message-navbar-user {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 60px
}

.message-navbar-user-avatar {
  display: flex;
  align-items:center;
  justify-content:center;
  height: 60px
}

.message-navbar-user-name {
  margin-left: 10px;
  display: flex;
  align-items:center;
  justify-content:center;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  height: 60px
}

.message-navbar-user-member-dreamer {
  color: #fff;
  background: #e55500;
  font-size: 14px;
  height: 16px;
  border-radius: 4px;
  padding: 5px;
}

.message-navbar-user-member-initiator {
  color: #fff;
  background: #549600;
  font-size: 14px;
  height: 16px;
  border-radius: 4px;
  padding: 5px;
}

.message-navbar-user-member-elder {
  color: #fff;
  background: #006ee6;
  font-size: 14px;
  height: 16px;
  border-radius: 4px;
  padding: 5px;
}

.message-navbar-user-nonmember {
  color: #fff;
  background: #8d96a8;
  font-size: 14px;
  height: 16px;
  border-radius: 4px;
  padding: 5px;
}

.rce-navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  height: 40px;
  border-bottom: 1px solid #ccc;
}



.clear-both {
  clear: both;
}

.link {
  color: #178cd5;
  text-decoration: none;
}

a {
  color: #178cd5;
  text-decoration: none;
}

.buttonLink {
  color: #14009b;
  text-decoration: none;
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.menu-left-item:hover * {
   background: #191d21;
   height: 45px;
   line-height: 45px;
}

.menu-left-item-inside:hover * {
   background: #191d21;
   height: 45px;
   line-height: 45px;
   padding-left: 15px;
   margin-left: -15px;
}

/* HEADER */
.main_header {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: rgba(255, 255, 255, 0.98);
  overflow: hidden;
  border-bottom: 1px solid #ddd; }

.main_header_connected {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: rgba(255, 255, 255, 0.98);
  overflow: hidden;
  border-bottom: 1px solid #ddd; }


.main_header_inside {
  width: 98%;
  height: 50px;
  margin: auto;
  margin-top: 5px;
  overflow: hidden; }

.main_header_inside_mobile {
  width: 98%;
  height: 50px;
  margin: auto;
  margin-top: 5px;
  overflow: hidden; }

.main_header_inside_left {
  width: 170px;
  height: 50px;
  justify-content: center;
  line-height: 50px;
  float: left; }

.main_header_inside_left a {
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  font-size: 16px; }

.main_header_inside_left_2 {
  height: 50px;
  justify-content: center;
  line-height: 50px;
  float: left;
  margin-left: 30px; }

.main_header_inside_left_2 a {
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  font-size: 16px; }

.main_header_inside_left img {
  width: 170px;
  vertical-align: middle; }

.main_header_inside_left_mobile {
  width: 30%;
  height: 50px;
  justify-content: center;
  line-height: 50px;
  float: left;
  margin-left: 5px; }

.main_header_inside_left_mobile img {
  width: 100%;
  vertical-align: middle; }

.main_header_inside_center {
  margin-left: 20px;
  width: 480px;
  height: 44px;
  margin-top: 3px;
  justify-content: center;
  float: left;
}

.main_header_inside_right {
  position: relative;
  text-align: right;
  width: 350px;
  height: 50px;
  float: right; }

.main_header_inside_right img {
  height: 50px;
  vertical-align: middle; }

.main_header_inside_right_1 {
  position: relative;
  float: right;
  margin-left: 20px;
  margin-top: 5px; }

.main_header_inside_right_2 {
  position: relative;
  float: right;
  margin-top: 5px;
}

.avatar_menu_img {
  border-radius: 50%;
  border: 1px solid #3a3b3c; }

.avatar_menu_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  justify-content: center;
  line-height: 40px;
  color: #e4e6eb;
}

.sign_transp_wrapper {
  position: fixed;
  z-index: 10;
  top: 80px;
  right: 20px;
  /*	margin-right:-725px;*/
  width: 408px;
  height: 450px;
  overflow: hidden;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.8); }

.sign_transp_wrapper_home {
  position: absolute;
  z-index: 10;
  top: 80px;
  right: 20px;
  /*	margin-right:-725px;*/
  width: 408px;
  height: 450px;
  overflow: hidden;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.8);
}

.basic_ti {
  height: 40px;
  padding: 6px;
  background: #fff;
  width: 358px;
  border: 1px solid #111;
  border-radius: 4px;
  outline-style: none;
  box-shadow: none;
  border-color: transparent; }

.basic_ti_top {
  height: 40px;
  padding: 6px;
  background: #fff;
  width: 358px;
  border: 1px solid #111;
  border-radius: 4px;
  border-bottom: none;
  -moz-border-radius-bottomleft: 0px;
  -webkit-border-bottom-left-radius: 0px;
  border-bottom-left-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  -webkit-border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
  outline-style: none;
  box-shadow: none;
  border-color: transparent; }

.basic_ti_middle {
  height: 40px;
  padding: 6px;
  background: #fff;
  width: 358px;
  border: 1px solid #111;
  border-top: 1px solid #bbb;
  border-bottom: none;
  border-radius: none;
  -moz-border-radius-topleft: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  outline-style: none;
  box-shadow: none;
  border-color: transparent; }

.basic_ti_bottom {
  height: 40px;
  padding: 6px;
  background: #fff;
  width: 358px;
  border-radius: 4px;
  border: 1px solid #111;
  border-top: 1px solid #bbb;
  -moz-border-radius-topleft: 0px;
  -webkit-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}

.sign_transp_inner {
  padding: 20px;
  padding-top: 10px; }

.sign_title {
  font-size: 32px;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
  width: 370px;
}

.buttonBlackText {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  width: 200px;
  height: 40px;
  justify-content: center;
  line-height: 40px;
  border-radius: 4px;
  cursor: pointer; }

.buttonWhiteText {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  width: 200px;
  height: 40px;
  justify-content: center;
  line-height: 40px;
  border-radius: 4px;
  cursor: pointer; }



.rce-citem-body--top-title {
	text-align: left;
	font-weight: bold;
}

.white-round-container {
  background: #fff;
  width: 1020px;
  border-radius: 6px;
  border: 0.5pt solid #ddd; }

.padding-10 {
  padding: 10px; }

.padding-20 {
  padding: 20px; }

.global-activeuser-changed-icon {
  width: 60px;
  height: 60px;
  line-height: 65px;
  text-align: center;
  background: #8dc82c;
  border-radius: 50%;
  font-size: 45px;
  color: #FFF;
  margin: auto;
  margin-top: 30px; }

.page-title {
  color: #222;
  font-size: 35px;
  font-weight: bold;
  line-height: 40px; }

.clear-both {
  clear: both; }

.h40 {
  height: 40px; }

.float-25pc {
  float: left;
  width: 25%; }

.float-50pc {
  float: left;
  width: 50%; }

.page-subtitle-underlined {
  color: #222;
  font-size: 18px;
  line-height: 30px;
  width: 96%;
  border-bottom: 1px solid #ccc; }

.default-text {
  color: #333;
  font-size: 14px;
}


.Grid .Likes{
    cursor: pointer;
}
.Grid .Likes .goUp{
    display: inline-flex;
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    transition: 0.1s ease-in-out;
}
.Grid .Likes .waitDown{
    display: inline-flex;
    opacity: 0;
    transform: translate3d(0, 20px, 0);
}
.Grid .Likes .initial{
    display: inline-flex;
    opacity: 1;
    transform: translate3d(0, 0px, 0);
    transition: 0.1s ease-in-out;
}

.react-bootstrap-table table {
  /*display: block;*/
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.react-bootstrap-table td, .react-bootstrap-table th {
  vertical-align: middle !important;
}

.react-bootstrap-table th.sortable {
  white-space: nowrap;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e9ecef;
}
.react-bootstrap-table td, .react-bootstrap-table th {
  vertical-align: middle !important;
}
.table td, .table th {
  padding: .75rem;
  border-top: 1px solid #e9ecef;
}
th {
  font-size: 14px;
  color: #8493a5;
  font-weight: 500 !important;
}
th {
  text-align: inherit;
}

.table th {
  text-align: left

}

.fa-fw {
  width: 1.28571em;
  text-align: center;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
    font-size: 14px;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.text-muted {
  color: #adb7c3 !important;
}

.text-inverse-mid {
  color: #1f2d3d !important;
}
.text-inverse {
  color: #1f2d3d !important;
}

.sr-only {
  display: none;
}

.text-inverse-xxxx {
  color: #999;
}

.text-inverse-active {
  background-color: green;
}

.text-inverse-pending {
  background-color: orange;
}

.text-inverse-verifying {
  background-color: orange;
}

.text-inverse-suspended {
  background-color: #e55501;
}

.text-inverse-revoked {
  background-color: red;
}

.text-inverse-deleted {
  background-color: red;
}

.text-inverse-void {
  background-color: #9ea7b6;
}

.text-inverse-new {
  background-color: #20af28;
}

.text-inverse-link {
  color: #0079ff !important;
  cursor: pointer;
  font-size: 14px;
}

.row-edited td {
  animation: highlight 1500ms ease-out;
}
@keyframes highlight {
  0% {
    background-color: #e6ff85;
  }
  10% {
    background-color: #fff;
  }
  20% {
    background-color: #e6ff85;
  }
  30% {
    background-color: #fff;
  }
  40% {
    background-color: #e6ff85;
  }
  50% {
    background-color: #fff;
  }
  60% {
    background-color: #e6ff85;
  }
  70% {
    background-color: #fff;
  }
  80% {
    background-color: #e6ff85;
  }
  90% {
    background-color: #fff;
  }
  100% {
    background-color: #e6ff85;
  }
}

.row-selected td {
  background-color: #e8f3fc;
}




.pagination {
  display: inline-block;
  list-style: none;
  padding-left: 0;
  margin: 20px 0;
    margin-top: 20px;
  border-radius: 4px;
}
.react-bootstrap-table-page-btns-ul {
  float: right;
  margin-top: 0px !important;
}

.pagination > li {
  display: inline;
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}
.pagination > li > a, .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
    border-top-color: rgb(221, 221, 221);
    border-right-color: rgb(221, 221, 221);
    border-bottom-color: rgb(221, 221, 221);
    border-left-color: rgb(221, 221, 221);
}

a {
  color: #178cd5;
}
.image-gallery-slide img {
  background-color: #222;
}



@media (max-width: 600px) {
  /* CSS rules for screens with a width of 600px or less */
  .image-gallery-slide img {
    height: 400px;
    object-fit: contain;
  }
}

@media (min-width: 601px) and (max-width: 1200px) {
  /* CSS rules for screens with a width between 601px and 1200px */
  .image-gallery-slide img {
    height: 500px;
    object-fit: contain;
  }
}

@media (min-width: 1201px) {
  /* CSS rules for screens with a width of 1201px or more */
  .image-gallery-slide img {
    height: 625px;
    object-fit: contain;
  }
}

.image-gallery-thumbnails-wrapper {
    background-color: #111;
}

.image-gallery-thumbnail+.image-gallery-thumbnail {
  background-color: #111;
}


.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 10px;
}
